import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["timeFields", "allDayFields"];

    connect() {
        this.toggle({target: this.element.querySelector("[name='event[all_day]']")}); // Set visibility on page load
    }

    toggle(event) {
        const isChecked = event.target.checked;

        if (isChecked) {
            this.timeFieldsTarget.classList.add("hidden");
        } else {
            this.timeFieldsTarget.classList.remove("hidden");
        }
    }
}
