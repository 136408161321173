import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["sidebarContainer", "icon", "link", "mainContent"];

    connect() {
        const isExpanded = localStorage.getItem("sidebarExpanded") === "true";
        if (isExpanded) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    toggle() {
        const expand = !this.isSidebarExpanded();
        if (expand) {
            this.expand();
        } else {
            this.collapse();
        }
        localStorage.setItem("sidebarExpanded", expand.toString());
    }

    expand() {
        this.sidebarContainerTarget.classList.add("w-52");
        this.mainContentTarget.classList.remove("lg:ml-14");
        this.mainContentTarget.classList.add("lg:ml-52");
        this.linkTargets.forEach(link => {
            link.classList.remove("sr-only");
        });
        this.updateIcon(true);
    }

    collapse() {

        this.sidebarContainerTarget.classList.remove("w-52");
        this.mainContentTarget.classList.remove("lg:ml-52");
        this.mainContentTarget.classList.add("lg:ml-14");
        this.linkTargets.forEach(link => {
            link.classList.add("sr-only");
        });
        this.updateIcon(false);
    }

    isSidebarExpanded() {
        return this.sidebarContainerTarget.classList.contains("w-52");
    }

    updateIcon(isExpanded) {
        const icon = this.iconTarget.querySelector("svg");
        if (isExpanded) {
            icon.innerHTML = `<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"/>`;
        } else {
            icon.innerHTML = `<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"/>`;
        }
    }
}
