import {Controller} from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
    static targets = ["section", "entry"];
    static values = {createUrl: String, updateUrl: String, deleteUrl: String, moveUrl: String};

    connect() {
        console.log("Canvas controller connected");

        // Initialize Sortable for drag-and-drop functionality
        this.sectionTargets.forEach(section => {
            new Sortable(section, {
                group: "canvas-sections", // Allow drag-and-drop between sections
                animation: 150,
                handle: ".drag-handle",
                onEnd: this.handleSortEnd.bind(this)
            });
        });
    }

    addEntry(event) {
        const sectionName = event.currentTarget.dataset.sectionName;
        const section = this.sectionTargets.find(s => s.dataset.sectionName === sectionName);

        fetch(this.createUrlValue, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({
                canvas_entry: {section: sectionName, content: "", position: section.children.length}
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.addEntryToDOM(sectionName, data.entry); // Use real ID
                } else {
                    console.error("Error creating entry:", data.errors);
                }
            });
    }


    addEntryToDOM(sectionName, entry) {
        const section = this.sectionTargets.find(s => s.dataset.sectionName === sectionName);

        // Create the entry HTML with the correct ID
        const entryHtml = `
              <div class="entry flex items-center mb-2" data-entry-id="${entry.id}">
                <div class="drag-handle cursor-grab pr-1">☰</div>
                <input type="text"
                       class="p-1 rounded mr-2 w-full form-control border-0"
                       value="${entry.content}"
                       placeholder="Add details for ${sectionName.replace(/_/g, ' ')}"
                       data-canvas-target="entry"
                       data-entry-id="${entry.id}"
                       data-action="keydown.enter->canvas#updateEntry blur->canvas#updateEntry">
                <button class="text-xs text-red-500 ml-2" data-entry-id="${entry.id}" data-action="click->canvas#removeEntry">✕</button>
              </div>`;

        // Find the "Add Entry" button and insert the entry before it
        const addButton = section.querySelector('[data-action="click->canvas#addEntry"]');
        addButton.insertAdjacentHTML("beforebegin", entryHtml);
    }


    removeEntry(event) {
        const entryId = event.currentTarget.dataset.entryId;
        const url = this.deleteUrlValue.replace(":id", entryId);

        fetch(url, {
            method: "DELETE",
            headers: {
                "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    document.querySelector(`[data-entry-id="${entryId}"]`).remove();
                } else {
                    console.error("Error deleting entry");
                }
            });
    }

    updateEntry(event) {
        if (event.type === "keydown" && event.key !== "Enter") return;

        const entryId = event.currentTarget.dataset.entryId;
        const content = event.currentTarget.value;

        // Dynamically generate the update URL
        const updateUrl = this.updateUrlValue.replace(":id", entryId);

        fetch(updateUrl, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({canvas_entry: {content}})
        })
            .then(response => response.json())
            .then((data) => {
                if (data.success) {
                    // Update the view mode content
                    const entryView = this.element.querySelector(`[data-entry-id="${entryId}"][data-canvas-target="entryView"]`);
                    entryView.textContent = content || "Click to edit...";
                } else {
                    console.error("Error updating entry:", data.errors);
                }
            })
            .finally(() => {
                // Show view mode and hide edit mode
                const entryView = this.element.querySelector(`[data-entry-id="${entryId}"][data-canvas-target="entryView"]`);
                const entryInput = this.element.querySelector(`[data-entry-id="${entryId}"][data-canvas-target="entryInput"]`);
                entryView.classList.remove("hidden");
                entryInput.classList.add("hidden");
            });
    }

    handleSortEnd(event) {
        const entryId = event.item.dataset.entryId;
        const newSectionName = event.to.dataset.sectionName;
        const newPosition = Array.from(event.to.children).indexOf(event.item);

        // Replace :id in the URL with the actual entryId
        const moveUrl = this.moveUrlValue.replace(":id", entryId);

        fetch(moveUrl, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({
                canvas_entry: {section: newSectionName, position: newPosition}
            })
        })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    console.error("Error moving entry:", data.errors);
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }

    toggleEditMode(event) {
        const entryId = event.currentTarget.dataset.entryId;
        const entryView = this.element.querySelector(`[data-entry-id="${entryId}"][data-canvas-target="entryView"]`);
        const entryInput = this.element.querySelector(`[data-entry-id="${entryId}"][data-canvas-target="entryInput"]`);

        // Hide view mode and show edit mode
        entryView.classList.add("hidden");
        entryInput.classList.remove("hidden");
        entryInput.focus();
    }

}
