import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["target"];

    toggle(event) {
        const targetId = event.currentTarget.getAttribute("data-collapse-target");
        const target = document.querySelector(targetId);
        if (target) {
            target.classList.toggle("hidden");
        }
    }
}
