import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["shareClassSummary", "shareholderSummary", "toggleButton"];

    connect() {
        // Initially set the correct state based on your logic (both might be hidden initially depending on your needs)
        this.shareholderSummaryTarget.classList.add("hidden");
    }

    toggle() {
        if (this.shareClassSummaryTarget.classList.contains("hidden")) {
            this.shareClassSummaryTarget.classList.remove("hidden");
            this.shareholderSummaryTarget.classList.add("hidden");
            this.toggleButtonTarget.textContent = "Show Shareholder Summary";
        } else {
            this.shareClassSummaryTarget.classList.add("hidden");
            this.shareholderSummaryTarget.classList.remove("hidden");
            this.toggleButtonTarget.textContent = "Show Share Class Summary";
        }
    }
}