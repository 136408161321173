// app/javascript/controllers/date_input_controller.js
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["display", "hidden"]

    connect() {
        this.initializeDisplayField()
    }

    formatDate(event) {
        const [day, month] = this.displayTarget.value.split('/').map(Number);
        if (day && month) {
            const year = new Date().getFullYear();
            const date = new Date(Date.UTC(year, month - 1, day));

            // Validate that the created date matches the input
            if (date.getUTCDate() === day && date.getUTCMonth() === month - 1) {
                const formattedDate = date.toISOString().split('T')[0];
                this.hiddenTarget.value = formattedDate;
            } else {
                console.error("Invalid date input.");
                // Optionally reset the input field or inform the user
            }
        }
    }

    initializeDisplayField() {
        if (this.hiddenTarget.value) {
            const date = new Date(this.hiddenTarget.value);
            const formattedDisplayDate = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
            this.displayTarget.value = formattedDisplayDate;
        }
    }
}
