import {Controller} from "@hotwired/stimulus";
import * as d3 from "d3";
import {OrgChart} from "d3-org-chart"; // Import the library
import html2canvas from 'html2canvas'; // Ensure this import is correct
window.OrgChart = OrgChart;

export default class extends Controller {
    static values = {data: Object};

    connect() {
        this.renderChart();
    }

    renderChart() {
        // Parse the data from the nodes key
        const flatData = this.dataValue.nodes;

        // Validate the data
        if (!flatData || flatData.length === 0) {
            console.error("Org Chart: No valid data found.");
            return;
        }

        // Initialize the org chart
        new OrgChart()
            .container(this.element) // Target the chart container
            .data(flatData) // Pass the flat data array (from nodes)
            .rootMargin(100)
            .nodeWidth((d) => 210)
            .nodeHeight((d) => 140)
            .childrenMargin((d) => 130)
            .compactMarginBetween((d) => 75)
            .compactMarginPair((d) => 80)
            // Custom link appearance
            .linkUpdate(function (d, i, arr) {
                d3.select(this)
                    .attr("stroke", d.data._upToTheRootHighlighted ? "#152785" : "lightgray")
                    .attr("stroke-width", d.data._upToTheRootHighlighted ? 5 : 1.5)
                    .attr("stroke-dasharray", "4,4");

                if (d.data._upToTheRootHighlighted) {
                    d3.select(this).raise();
                }
            })
            // Custom node content
            .nodeContent((d) => {
                const color = ["#6E6B6F", "#18A8B6", "#F45754", "#96C62C", "#BD7E16", "#802F74"][d.depth % 6];
                const dimensions = {
                    image: 80,
                    lightCircle: 95,
                    outsideCircle: 110,
                };

                return `
                    <div style="position: absolute; width: ${d.width}px; height: ${d.height}px;">
                      <div 
                        style="
                          background-color: ${color}; 
                          position: absolute; 
                          top: 50%; 
                          left: 50%; 
                          transform: translate(-50%, -50%); 
                          border-radius: 50%; 
                          width: ${dimensions.outsideCircle}px; 
                          height: ${dimensions.outsideCircle}px;">
                      </div>
                      <div 
                        style="
                          background-color: #ffffff; 
                          position: absolute; 
                          top: 50%; 
                          left: 50%; 
                          transform: translate(-50%, -50%); 
                          border-radius: 50%; 
                          width: ${dimensions.lightCircle}px; 
                          height: ${dimensions.lightCircle}px;">
                      </div>
                      <img 
                        src="${d.data.imageUrl}" 
                        alt="Profile Image" 
                        style="
                          position: absolute; 
                          top: 50%; 
                          left: 50%; 
                          transform: translate(-50%, -50%); 
                          border-radius: 50%; 
                          width: ${dimensions.image}px; 
                          height: ${dimensions.image}px;"/>
                      <div 
                        class="card" 
                        style="
                          position: absolute; 
                          top: calc(50% + ${dimensions.outsideCircle / 2 + 10}px); 
                          left: 0; 
                          width: 100%; 
                          /*background-c/olor: #3AB6E3; */
                          text-align: center;">
                        <div 
                          style="
                            background-color: ${color}; 
                            color: #ffffff; 
                            font-weight: bold; 
                            font-size: 16px; 
                            padding: 5px 0;">
                          ${d.data.name}
                        </div>
                        <div 
                          style="
                            background-color: #F0EDEF; 
                            color: #424142; 
                            font-size: 16px; 
                            padding: 5px 0;">
                          ${d.data.positionName}
                        </div>
                      </div>
                    </div>
                  `;
            })

            .render(); // Render the org chart
    }

    exportChart() {
        html2canvas(document.querySelector('#chartContainer'), {
            scale: 4 // Increase scale for higher resolution
        }).then(canvas => {
            let link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'orgchart.png';
            link.click();
        });
    }
}


