import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["user"]; // Register `user` targets to get references to user cards

    initialize() {
        this.markerElements = []; // To track markers if dynamically added
    }

    connect() {
        console.log("Stimulus UserHighlight controller connected");
    }

    // Call this when adding map markers dynamically
    registerMarker(markerElement) {
        this.markerElements.push(markerElement);
        markerElement.addEventListener("click", this.handleMarkerClick.bind(this));
    }

    handleMarkerClick(event) {
        const marker = event.currentTarget;
        const userId = marker.dataset.userId;

        // Remove any existing highlights
        this.userTargets.forEach(card => card.classList.remove("border-yellow-400", "bg-yellow-100"));

        // Highlight the user card
        const userCard = this.userTargets.find(card => card.dataset.userId === userId);
        if (userCard) {
            userCard.classList.add("border-yellow-400", "bg-yellow-100");
            userCard.scrollIntoView({behavior: "smooth", block: "center"});
        }
    }
}