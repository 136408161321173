import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "latitude", "longitude", "location", "address"];

    connect() {
        this.initAutocomplete();
    }

    initAutocomplete() {
        if (typeof google === "undefined") {
            console.error("Google Maps API is not loaded");
            return;
        }

        this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
            fields: ["geometry", "formatted_address"],
        });

        this.autocomplete.addListener("place_changed", this.placeChanged.bind(this));
    }

    placeChanged() {
        const place = this.autocomplete.getPlace();

        if (!place.formatted_address) {
            console.error("No formatted_address found.");
            return;
        }

        if (this.hasAddressTarget) {
            this.addressTarget.value = place.formatted_address;
        }

        if (place.geometry) {
            this.latitudeTarget.value = place.geometry.location.lat();
            this.longitudeTarget.value = place.geometry.location.lng();
            this.locationTarget.value = place.formatted_address;
        }
    }
}